import { getLocalization } from 'global/global';
import * as React from 'react';
import { Col, Form, } from 'react-bootstrap';

interface Props {
  value: string;
  onChange: (value: string | null) => void;
}

export default function TransactionBundle(props: Props) {

  const onClick = (value) => {
    if (props.value === value) {
      props.onChange(null);
    }
  };

  return (
    <Form.Group as={Col}>
      <Form.Label>{getLocalization('transactionBundle')}</Form.Label>
      <Form.Check
        id={`tx-bundle-500`}
        type={'radio'}
        label={`500 ${getLocalization('transactions')} - $500`}
        name={'tx-bundle'}
        checked={props.value === '500'}
        onChange={(e) => props.onChange(e.target.checked ? '500' : null )}
        onClick={() => onClick('500')}
      />
      <Form.Check
        id={`tx-bundle-1000`}
        type={'radio'}
        label={`1000 ${getLocalization('transactions')} - $800`}
        name={'tx-bundle'}
        checked={props.value === '1000'}
        onChange={(e) => props.onChange(e.target.checked ? '1000' : null )}
        onClick={() => onClick('1000')}
      />
      <Form.Check
        id={`tx-bundle-2500`}
        type={'radio'}
        label={`2500 ${getLocalization('transactions')} - $1750`}
        name={'tx-bundle'}
        checked={props.value === '2500'}
        onChange={(e) => props.onChange(e.target.checked ? '2500' : null )}
        onClick={() => onClick('2500')}
      />
      <Form.Check
        id={`tx-bundle-5000`}
        type={'radio'}
        label={`5000 ${getLocalization('transactions')} - $3000`}
        name={'tx-bundle'}
        checked={props.value === '5000'}
        onChange={(e) => props.onChange(e.target.checked ? '5000' : null )}
        onClick={() => onClick('5000')}
      />
      <Form.Check
        id={`tx-bundle-10000`}
        type={'radio'}
        label={`10000 ${getLocalization('transactions')} - $4500`}
        name={'tx-bundle'}
        checked={props.value === '10000'}
        onChange={(e) => props.onChange(e.target.checked ? '10000' : null )}
        onClick={() => onClick('10000')}
      />
    </Form.Group>
  );
}
