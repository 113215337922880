import '../styles/UserQuestion.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { Button } from 'react-bootstrap';
import NewDocument from 'views/Document/NewDocument';
import {
  DocumentFileInterface, DocumentInterface, DocumentVersionInterface, INITIAL_DOCUMENT
} from 'Interfaces/DocumentInterface';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { DataPoint } from 'Interfaces/DataPoint';
import GenericModal from '../../../views/Modals/GenericModal';
import { getLocalization, globalWindow } from '../../../global/global';
import { formatDate, isNullOrUndefined } from '../../../utils/utils';
import DocumentDetails from '../../Document/DocumentDetails';
import { SingleInstanceComponentClass } from '../SingleInstanceComponent';

interface State {
  showModal: boolean;
  exportType: string;
  includeImages: boolean;
  skipEmpty: boolean;
  zoomLevel: string;
  hasError: boolean;
  imageSize: 'small' | 'medium' | '';
  confirmSaveExport: boolean;
  pdf: boolean;
  exportAsDocument: boolean;
  document: DocumentInterface;
  documentFile?: DocumentFileInterface;
  documentVersion: DocumentVersionInterface;
  versions: DocumentVersionInterface[];
}

interface Props {
  dataPoint: DataPoint;
  model: FormInterface;
  singleInstance: SingleInstanceComponentClass;
  getDataPointDocumentVersions: (id: string, format: string, signal: AbortSignal) => Promise<DocumentInterface[]>;
  history?: boolean;
}

const INITIAL_VERSION: DocumentVersionInterface = {
  version: '',
  editor: globalWindow.userID,
  editDate: formatDate(new Date()),
  changeLog: ''
};

export default class ExportButton extends React.Component <Props, State> {

  private abortController: AbortController = new AbortController();
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  @bind
  private getInitialState(): State {
    const { model } = this.props;
    const initialState: State = {
      showModal: false,
      includeImages: false,
      exportType: '',
      skipEmpty: false,
      zoomLevel: 'high',
      hasError: false,
      imageSize: '',
      confirmSaveExport: false,
      pdf: false,
      exportAsDocument: false,
      document: {...INITIAL_DOCUMENT},
      documentVersion: INITIAL_VERSION,
      versions: []
    };
    if (model.hasWordTemplate) {
      initialState.exportType = 'word_template';
      initialState.includeImages = true;
    } else if (model.hasExcelTemplate) {
      initialState.exportType = 'excel_template';
      initialState.includeImages = true;
    }
    return initialState;
  }

  @bind
  private onClick() {
    const formComponent = this.props.singleInstance.formComponent.current;
    if (!this.props.history && formComponent && formComponent.getUpdated()) {
      this.setState({ confirmSaveExport: true });
    } else {
      this.setState({ showModal: true });
    }
  }

  @bind
  private closeModal() {
    this.setState({ showModal: false, confirmSaveExport: false });
  }

  @bind
  private exportWithoutSave() {
    this.setState({ showModal: true, confirmSaveExport: false });
  }

  @bind
  private onConfirmSaveExport() {
    this.closeModal();
    this.props.singleInstance.savePOI('VIEW', true);
  }

  @bind
  public initExport() {
    this.setState({ showModal: true });
  }

  @bind
  private onExport() {
    const {
      exportType, includeImages, skipEmpty, zoomLevel,
      imageSize, pdf, document, exportAsDocument,
      documentFile, documentVersion, versions
    } = this.state;
    const { dataPoint, model } = this.props;
    const { readOnlyOwn, groupID, userID, pwrd, userName, lang } = globalWindow;
    if (exportType === '') {
      this.setState({ hasError: true });
      return;
    }
    if (exportAsDocument && (
      (!document.name && versions.length === 0) || !documentVersion.changeLog || !documentVersion.version
    )) {
      this.setState({ hasError: true });
      return;
    }
    const mainUrl = `/json/app/export/${model.id}/${exportType}/${readOnlyOwn}/${groupID}/${userID}?`;
    const p: string[] = [];
    p.push(`key=${pwrd}`);
    p.push(`userid=${userName}`);
    p.push(`pictures=${includeImages ? 1 : 0}`);
    p.push(`pages=0`);
    p.push(`history=0`);
    p.push(`id=${dataPoint.id}`);
    p.push(`projectid=0`);
    p.push(`dgmimages=${zoomLevel === 'none' ? 0 : 1}`);
    p.push(`zoomLevel=${zoomLevel}`);
    p.push(`skipEmpty=${skipEmpty ? 1 : 0}`);
    p.push(`lang=${lang}`);
    p.push(`imageSize=${imageSize}`);
    p.push(`pdf=${pdf ? 1 : 0}`);
    if (exportAsDocument) {
      const doc = {...document};
      doc.files = [];
      if (this.state.versions.length === 0) {
        p.push(`document=${encodeURIComponent(JSON.stringify(doc))}`);
      }
      p.push(`file=${documentFile ? encodeURIComponent(JSON.stringify([documentFile])) : ''}`);
      p.push(`version=${documentVersion ? encodeURIComponent(JSON.stringify(documentVersion)) : ''}`);
    }
    globalWindow.open(mainUrl + p.join('&'), '_blank');
    this.setState({
      documentVersion: INITIAL_VERSION,
      document: INITIAL_DOCUMENT,
      exportAsDocument: false,
      versions: [],
      hasError: false
    });
    this.closeModal();
  }

  @bind
  private handleExportTypeChange(e) {
    const { model } = this.props;
    const newState = {};
    newState['exportType'] = e.target.value;
    if (newState['exportType'] === 'word_template') {
      if (model.hasWordTemplate) {
        newState['includeImages'] = true;
      } else {
        newState['includeImages'] = false;
      }
    } else if (newState['exportType'] === 'excel_template') {
      newState['includeImages'] = true;
    } else {
      newState['includeImages'] = false;
    }
    this.setState(newState, () => {
      if (this.state.exportAsDocument) {
        void this.setDocumentExport();
      }
    });
  }

  getFileExtension = () => {
    const { pdf, exportType } = this.state;
    if (pdf) {
      return '.pdf';
    } else if (exportType === 'word_template') {
      return '.docx';
    } else if (exportType === 'excel_template') {
      return '.xlsx';
    }
    return '';
  };

  getFormat = () => {
    const { pdf, exportType } = this.state;
    if (pdf) {
      return 'pdf';
    } else if (exportType === 'word_template') {
      return 'word';
    } else if (exportType === 'excel_template') {
      return 'excel';
    }
    return exportType;
  };

  reloadVersions = async () => {
    const { dataPoint } = this.props;
    const d = await this.props.getDataPointDocumentVersions(
      `${dataPoint['id']}`, this.getFormat(), this.abortController.signal
    ).then(j => j);
    if (d.length > 0) {
      this.setState({ versions: d });
    }
  };

  setDocumentExport = async () => {
    const { dataPoint } = this.props;
    const newState = {};
    const format = this.getFormat();
    const d = await this.props.getDataPointDocumentVersions(
      `${dataPoint['id']}`, format, this.abortController.signal
    ).then(j => j);
    console.log(d);
    if (d.length > 0) {
      console.log(d);
      newState['versions'] = d;
      newState['documentVersion'] = {
        version: '',
        editor: globalWindow.userID,
        editDate: formatDate(new Date()),
        changeLog: '',
        documentId: d[0]['documentId']
      };
    } else {
      newState['document'] = {
        ...INITIAL_DOCUMENT,
        name: dataPoint['Name'],
        creationDate: new Date(),
        editedBy: dataPoint['user_id'],
        createdBy: dataPoint['createdBy'],
        format: this.getFormat(),
        form: dataPoint['questionnaire_id'],
        locations: [1, 2, 3, 4].map(l => dataPoint[`location${l}`]).filter(l => !isNullOrUndefined(l)).join(','),
        files: [{ name: `${dataPoint['Name']}${this.getFileExtension()}` }],
        dataPointId: dataPoint['id']
      };
      newState['documentFile'] = {
        name: `${dataPoint['Name']}${this.getFileExtension()}`
      };
      newState['documentVersion'] = {
        version: '',
        editor: globalWindow.userID,
        editDate: formatDate(new Date()),
        changeLog: ''
      };
    }
    this.setState(newState);
  };

  @bind
  private handleCheckChange(e) {
    const newState = {};
    newState[e.target.name] = e.target.checked ? true : false;
    if (e.target.name === 'exportAsDocument') {
      this.setState({ exportAsDocument: e.target.checked }, () => {
        if (e.target.checked && this.state.exportType) {
          void this.setDocumentExport();
        }
      });
      return;
    }
    this.setState(newState);
  }

  @bind
  private handleRadioChange(e) {
    const newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  @bind
  private getExportBody(): JSX.Element {
    const { model } = this.props;
    const { exportType, hasError } = this.state;
    const noTemplateAlert = exportType === 'word_template' ? (
      <React.Fragment>
        {
          globalWindow.enableWordtemplate !== 'true' ? (
            <div className="form-group">
              <label>{getLocalization('customTemplate')}</label>
            </div>
          ) : null
        }
        {
          globalWindow.enableWordtemplate === 'true' && !model.hasWordTemplate ? (
            <div className="form-group">
              <label>{getLocalization('ownTemplageMsg')}</label>
            </div>
          ) : null
        }
      </React.Fragment>
    ) : null;
    return (
      <div className="container">
        <div className={`form-group ${hasError ? 'has-error' : ''}`}>
          <select
            name="exportType"
            className="form-control"
            value={this.state.exportType}
            onChange={this.handleExportTypeChange}
          >
            <option value="">Select one</option>
            <option value="excel_template">{getLocalization('excelExport')}</option>
            <option value="word_template">{getLocalization('wordExport')}</option>
          </select>
        </div>
        {
          exportType === 'excel_template' || (exportType === 'word_template' && !model.hasWordTemplate) ? (
            <div className="checkbox images_single">
              <input
                className="form-check-input"
                type="checkbox"
                name="includeImages"
                onChange={this.handleCheckChange}
                checked={this.state.includeImages}
              />
              <label className="form-check-label">
                {getLocalization('includeimages')}
              </label>
            </div>
          ) : null
        }

        { // check if to show skip empty values selection
          exportType === 'word_template' && !model.hasWordTemplate ? (
            <div className="checkbox skipempty_single">
              <input
                className="form-check-input"
                type="checkbox"
                name="skipEmpty"
                value="1"
                onChange={this.handleCheckChange}
              />
              <label className="form-check-label">
                {getLocalization('skipemptyrows')}
              </label>
            </div>
          ) : null
        }
        { // check if to show skip empty values selection
          exportType === 'word_template' && model.hasWordTemplate ? (
            <>
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="reducedImage"
                  value="1"
                  onChange={(e) => this.setState({ imageSize: e.target.checked ? 'small' : ''})}
                  checked={this.state.imageSize === 'small'}
                  id="image-size-small-check"
                />
                <label className="form-check-label" htmlFor="image-size-small-check">
                  {getLocalization('smallImages')}
                </label>
              </div>
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="reducedImage"
                  value="1"
                  onChange={(e) => this.setState({ imageSize: e.target.checked ? 'medium' : ''})}
                  checked={this.state.imageSize === 'medium'}
                  id="image-size-medium-check"
                />
                <label className="form-check-label" htmlFor="image-size-medium-check">
                  {getLocalization('mediumImages')}
                </label>
              </div>
            </>
          ) : null
        }
        { // check if to show background diagram zoom level selection
          exportType === 'word_template' && model.backgroundDiagram ? (
            <div className="form-group zoom_level">
              <div className="checkbox include_generated_images_single">
                <label>
                  {getLocalization('includegeneratedimages')}
                </label>
              </div>
              <div className="radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="zoomLevel"
                  value="none"
                  checked={this.state.zoomLevel === 'none'}
                  onChange={this.handleRadioChange}
                  id="zoomLevel-none-check"
                />
                <label className="form-check-label" htmlFor="zoomLevel-none-check">
                  {getLocalization('none')}
                </label>
              </div>
              <div className="radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="zoomLevel"
                  value="low"
                  checked={this.state.zoomLevel === 'low'}
                  onChange={this.handleRadioChange}
                  id="zoomLevel-low-check"
                />
                <label className="form-check-label" htmlFor="zoomLevel-low-check">
                  {getLocalization('zoomLow')}
                </label>
              </div>
              <div className="radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="zoomLevel"
                  value="medium"
                  checked={this.state.zoomLevel === 'medium'}
                  onChange={this.handleRadioChange}
                  id="zoomLevel-medium-check"
                />
                <label className="form-check-label" htmlFor="zoomLevel-medium-check">
                  {getLocalization('zoomMedium')}
                </label>
              </div>
              <div className="radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="zoomLevel"
                  value="high"
                  checked={this.state.zoomLevel === 'high'}
                  onChange={this.handleRadioChange}
                  id="zoomLevel-high-check"
                />
                <label className="form-check-label" htmlFor="zoomLevel-high-check">
                  {getLocalization('zoomHigh')}
                </label>
              </div>
            </div>
          ) : null
        }
        { // check if to show skip empty values selection
          ((exportType === 'word_template' && model.hasWordTemplate)
            || (exportType === 'excel_template' && model.hasExcelTemplate)
          ) ? (
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="pdf"
                  value="1"
                  onChange={this.handleCheckChange}
                  checked={this.state.pdf}
                />
                <label className="form-check-label">
                  {getLocalization('exportAsPdf')}
                </label>
              </div>
            ) : null
        }
        <div className="checkbox">
          <input
            className="form-check-input"
            type="checkbox"
            name="exportAsDocument"
            value="1"
            onChange={this.handleCheckChange}
            checked={this.state.exportAsDocument}
          />
          <label className="form-check-label">
            {getLocalization('exportAsDocument')}
          </label>
        </div>
        {noTemplateAlert}
        {this.getDocumentBody()}
      </div>
    );
  }

  getDocumentBody = () => {
    return this.state.exportAsDocument && this.state.exportType && (
      this.state.versions.length === 0 ? (
        <NewDocument
          document={this.state.document}
          updateDocument={this.updateDocument}
          updateVersion={this.updateVersion}
          files={this.state.documentFile ? [this.state.documentFile] : []}
          version={this.state.documentVersion}
          export={true}
          validate={this.state.hasError}
        />
      ) : (
        <DocumentDetails
          versions={this.state.versions}
          updateDocument={this.updateDocument}
          updateVersion={this.updateVersion}
          file={this.state.documentFile}
          version={this.state.documentVersion}
          reloadVersions={() => void this.reloadVersions()}
          export={true}
          validate={this.state.hasError}
        />
      )
    );
  };

  updateDocument = (document: DocumentInterface) => {
    this.setState({ document });
  };

  updateVersion = (documentVersion: DocumentVersionInterface) => {
    this.setState({ documentVersion });
  };

  public render(): JSX.Element {
    return (
      <React.Fragment>
        { this.state.showModal ? (
          <GenericModal
            visible={this.state.showModal}
            onConfirm={this.onExport}
            cancel={this.closeModal}
            body={this.getExportBody()}
            title={getLocalization('exportType')}
            confirmText={getLocalization('exportData')}
            cancelText={getLocalization('cancel')}
            dialogClassName={this.state.versions.length > 0 ? "modal-xl" : ''}
          />
        )
          : null }
        { this.state.confirmSaveExport ? (
          <GenericModal
            visible={this.state.confirmSaveExport}
            onConfirm={this.onConfirmSaveExport}
            cancel={this.exportWithoutSave}
            body={<p>{getLocalization('poiExportChangesAlert')}</p>}
            title={getLocalization('info')}
            confirmText={getLocalization('yes')}
            cancelText={getLocalization('no')}
          />
        )
          : null }
        <Button size="sm" onClick={this.onClick} id={'single-instance-export-btn'}>
          <i className="fa fa-download" aria-hidden="true" />
          {` ${getLocalization('exportData')}`}
        </Button>
      </React.Fragment>
    );
  }
}

/* const mapDispatchToProps = (dispatch) => {
  return {
    getDataPointDocumentVersions: (id: string, format, signal: AbortSignal) => {
      return dispatch(getDataPointDocumentVersions(id, format, signal));
    },
  };
};

export default connect(null, mapDispatchToProps)(ExportButton);*/
