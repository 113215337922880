import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'index';
import { FiltersMenuInterface } from '../../Interfaces/FiltersMenuInterface';
import { ClientPersistInterface } from '../../Interfaces/ClientPersistInterface';
import { FeedItem } from '../../Interfaces/FeedItem';
import { uniqueFormsSelector } from '../../reducers/formsReducer';
import { clearAutoUpdatedFeed, getFeed, setFeedFilter } from '../../actions/feed';
import { setSingleInstance } from '../../actions/moduleSelectionActions';
import { SingleInstance } from '../../Interfaces/ModuleSelectionInterface';
import { FormsInterface } from '../../Interfaces/Forms/FormsInterface';
import { FeedInterface } from '../../Interfaces/FeedInterface';
import FeedComponent from './FeedComponent';

interface StateProps {
  clientPersist: ClientPersistInterface;
  feed: FeedInterface;
  autoUpdateFeed: FeedItem[];
  filtersMenu: FiltersMenuInterface;
  forms: FormsInterface;
}

interface ActionProps {
  getFeed: (rowId: number, offSet: number, signal: AbortSignal, filter?: string) => void;
  clearAutoUpdatedFeed: (feed) => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  setFeedFilter: (filter: string) => void;
}

export type FeedProps = StateProps & ActionProps;

export default function FeedContainer() {
  const clientPersist = useAppSelector(state => state.clientPersist);
  const feed = useAppSelector(state => state.feed);
  const autoUpdateFeed = useAppSelector(state => state.autoUpdateFeed);
  const filtersMenu = useAppSelector(state => state.filtersMenu);
  const forms = useAppSelector(state => uniqueFormsSelector(state));

  const dispatch = useAppDispatch();
  return (
    <FeedComponent
      clientPersist={clientPersist}
      feed={feed}
      autoUpdateFeed={autoUpdateFeed}
      filtersMenu={filtersMenu}
      forms={forms}
      getFeed={(rowId: number, offSet: number, signal: AbortSignal, filter?: string) => {
        dispatch(getFeed(rowId, offSet, signal, filter));
      }}
      clearAutoUpdatedFeed={(feed) => dispatch(clearAutoUpdatedFeed(feed))}
      setSingleInstance={(singleInstance) => dispatch(setSingleInstance(singleInstance))}
      setFeedFilter={(filter) => dispatch(setFeedFilter(filter))}
    />
  );
}
