import { getRequestConfig } from 'api';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { toast } from 'react-toastify';
import { Payment, PaymentPlanInfo, PaymentRegistration, PaymentType } from 'Interfaces/PaymentPlanInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { getLocalization } from 'global/global';
import * as paymentsApi from '../api/paymentsApi';
import { PAYMENTS } from './actionTypes';

export const getPaymentPlanInfo = () => {
  return (dispatch, getState: () => StateInterface) => {
    const { clientPersist } = getState();
    if (clientPersist.user_id) {
      void paymentsApi
        .doGetPaymentPlanInfo()
        .then((res) => res.json())
        .then((json) => {
          dispatch(setPaymentPlanInfo(json.plan, json.card));
        });
    }
  };
};

export const setPaymentPlanInfo = (paymentPlanInfo: PaymentPlanInfo, card: JSONInterface) => ({
  type: PAYMENTS.LOADED_PLAN_INFO,
  paymentPlanInfo,
  card,
});

/**
 *
 * @param nonce
 * @param vatNo
 * @returns
 */
export const registerCard = (paymentRegistration: PaymentRegistration) => {
  return async (): Promise<boolean> => {
    const config = getRequestConfig();
    const response = await fetch('/json/app/epayment/registerbraintreecustomer', {
      method: 'POST',
      body: JSON.stringify(paymentRegistration),
      ...config,
    });
    const msg = await response.json();
    if (msg.status === 'OK') {
      return true;
    }
    return false;
  };
};

export const getClientToken = () => {
  return async (): Promise<string> => {
    const config = getRequestConfig();
    const response = await fetch('/json/app/epayment/clienttoken', {
      method: 'POST',
      ...config,
    });
    const token = await response.json();
    if (token.token) {
      return token.token;
    }
    return '';
  };
};

export const payWithCard = (payment: Payment, paymentType: PaymentType) => {
  const getUrlAction = () => {
    switch (paymentType) {
      case 'NEW':
        return 'transact';
      case 'MORE_TRANSACTIONS':
        return 'updateTransactionCount';
      case 'RENEW':
        return 'renewPayment';
      case 'MORE_USERS':
        return 'setAdditionalUsers';
      case 'ONE_TIME_PAYMENT':
        return 'oneTimePayment';
    }
  };
  return async (): Promise<boolean> => {
    const config = getRequestConfig();
    const response = await fetch(`/json/app/epayment/${getUrlAction()}`, {
      method: 'POST',
      body: JSON.stringify(payment),
      ...config,
    });
    const msg = await response.json();
    if (msg.status === 'OK') {
      return true;
    }
    return false;
  };
};

export const setAdditionalUsers = (additionalUsers: JSONInterface) => {
  return async (): Promise<boolean> => {
    const config = getRequestConfig();
    const response = await fetch('/json/app/epayment/setAdditionalUsers', {
      method: 'POST',
      body: JSON.stringify(additionalUsers),
      ...config,
    });
    const msg = await response.json();
    if (msg.status === 'OK') {
      return true;
    }
    return false;
  };
};

export const getPaymenyTransactions = () => {
  return async (dispatch) => {
    const config = getRequestConfig();
    const response = await fetch('/json/app/epayment/braintreefindTransaction', {
      ...config,
    });
    const json = await response.json();
    dispatch(setPaymentTransactions(json));
  };
};

export const setPaymentTransactions = (transactions: JSONInterface) => ({
  type: PAYMENTS.LOADED_TRANSACTIONS,
  transactions,
});


export const deletePaymentMethod = (token: string) => {
  return (dispatch) => {
    const config = getRequestConfig();
    void fetch(`/json/app/epayment/deletePaymentMethod/${token}`, {
      method: 'GET',
      ...config,
    }).then(res => res.json()).then(json => {
      if (json.status === 'OK') {
        dispatch(removePaymentMethod(token));
        toast(getLocalization('cardRemoved'), {
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        toast(json.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    });
  };
};

export const removePaymentMethod = (token: string) => ({
  type: PAYMENTS.DELETE_PAYMENT_METHOD, token
});

export const cancelAutoRenew = () => {
  return (dispatch) => {
    const config = getRequestConfig();
    const cancelToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('cancellingAutoRenew'), {
      toastId: cancelToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false,
    });
    fetch(`/json/app/epayment/cancelAutoRenew`, {
      method: 'DELETE',
      ...config,
    }).then(response => response.json()).then(json => {
      if (json.status === 'OK') {
        toast.update(cancelToastId, {
          type: toast.TYPE.SUCCESS,
          render: getLocalization('autoRenewCancelled'),
        });
        dispatch(getPaymentPlanInfo());
      } else {
        toast.update(cancelToastId, {
          type: toast.TYPE.SUCCESS,
          render: getLocalization('autoRenewCancelError'),
        });
      }
      setTimeout(() => toast.dismiss(cancelToastId), 5000);
    }).catch(() => {
      toast.update(cancelToastId, {
        type: toast.TYPE.SUCCESS,
        render: getLocalization('autoRenewCancelError'),
      });
      setTimeout(() => toast.dismiss(cancelToastId), 5000);
    });
  };
};
