import './LoginComponent.scss';
import * as React from 'react';
import bind from 'bind-decorator';
import { GoogleLogin } from 'react-google-login';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactCookieProps } from 'react-cookie';
import { getLocalization, globalWindow } from '../../global/global';
import GenericModal from '../../views/Modals/GenericModal';
import { getExpiryDate } from '../../utils/cookies';
import { getQueryParams, validEmail } from '../../utils/utils';
// import loginLocalizations from './loginLocalizations';
import { languagesEnum } from '../../Interfaces/ClientPersistInterface';
import { PasswordInput } from '../components/PasswordInput';
import { LoginPropsInterface } from './LoginContainer';
import TwoFaComponent from './TwoFaComponent';

interface IOwnState {
  username: string;
  password: string;
  showAccount?: boolean;
  resetEmail: string;
  rememberme: boolean;
  account: string;
  usernameError?: boolean;
  passwordError?: boolean;
  accountError?: boolean;
  showPassAcc?: boolean;
  isIE: boolean;
  resetPassword: boolean;
  resetPasswordError: string;
  fixedAccount: boolean; // if account is fixed, change account is not available.
}

type IExternalProps = LoginPropsInterface & RouteComponentProps & ReactCookieProps;

class LoginComponentClass extends React.Component <RouteComponentProps & IExternalProps, IOwnState> {
  constructor(props) {
    super(props);

    const rememberMe = props.cookies.get('rememberme');
    const password = props.cookies.get('loga1');
    const initialState = { username: '', password: '', account: '', showAccount: false, resetPasswordError: '',
      resetEmail: '', rememberme: false , isIE: false, showPassAcc: false, fixedAccount: false};
    if (rememberMe === 'true' && password !== null) {
      initialState.rememberme = true;
      initialState.username = props.cookies.get('loga');
      initialState.password = password;
      initialState.showPassAcc = true; // show password and account already.
    }
    initialState.isIE = !!globalWindow.document.documentMode;
    const {c: queriedAccount, userName} = getQueryParams(props.location.search);
    if (queriedAccount) {
      initialState.account = queriedAccount;
    } else {
      const db = props.cookies.get('db');
      if (db) {
        initialState.account = db;
      }
    }
    if (userName) {
      initialState.username = userName;
    }
    this.isFixedAccount(initialState);
    initialState.showAccount = initialState.account === '' ?  true : false;
    this.state = {...initialState, resetPassword: false};
    const host = globalWindow.location.host;
    if (host.indexOf('wabtec.poimapper.com') !== -1 || host.indexOf('framatome.poimapper.com') !== -1) {
      globalWindow.open(`/json/auth/samlrequest/x/portal`, '_parent');
    }
  }

  /**
   * This function sets if the account should be fixed.
   * An account is fixed if DV has been loaded from the accounts subdomain i.e <account>.poimapper.com
   * If the subdomain belongs to an account, change account would not be visible and any account that is set
   * would be reset to the domains account.
   */
  @bind
  private isFixedAccount(initialState) {
    const host = globalWindow.location.host;
    if (host.indexOf('.poimapper.com') !== -1) {
      const server = host.substring(0, host.indexOf('.poimapper.com'));
      const reserved = ['portal', 'dev', 'gw2', 'alpha', 'beta', 'gw2a', 'gw2b'];
      if (reserved.indexOf(server) === -1) {
        initialState.account = server;
        initialState.fixedAccount = true;
        const { cookies } = this.props;
        cookies?.set('db', server);
      }
    }
  }

  /*
    When the component is mounted
      - initialize google login
      - if the country cookie isn't set, get locate the user inorder to set the language.
    For IE, we do not do this as IE is not supported.
  */
  public componentDidMount() {
    const {lang} = getQueryParams(this.props.location.search);
    const loadedLanguage = lang ? lang as languagesEnum : languagesEnum.en;
    this.props.setLanguage(loadedLanguage);
    if (!this.state.isIE && this.props.cookies) {
      if (!this.props.cookies.get('geo_country')) {
        void this.props.geoLocationSensor(loadedLanguage, this.props.cookies);
      }
    }
  }

  /*
    This function handles login.
    if username or password is missing, we set the state for error display.
    if there is no error we initiate login process.
  */
  @bind
  private login(e) {
    e.preventDefault();
    const { username: userName, password, account: instance, rememberme, showAccount, showPassAcc } = this.state;

    const newState = {...this.state};
    if (!showPassAcc && userName !== '') {
      if (validEmail(userName)) {
        this.props.checkSAMLLogin(userName, this.useNormalLogin, instance);
        console.log('check email from backend');
        return;
      } else {
        this.setState({ showPassAcc: true });
        return;
      }
    }
    let hasError = false;
    if (userName === '') {
      newState.usernameError = true;
      hasError = true;
    }
    if (password === '') {
      newState.passwordError = true;
      hasError = true;
    }
    if (showAccount && instance === '') {
      // newState.accountError = true;
      // hasError = true;
    }
    if (hasError) {
      this.setState(newState);
      return;
    }
    if (!rememberme && this.props.cookies) {
      this.props.cookies.set('rememberme', false, {expires: getExpiryDate(7)});
      this.props.cookies.remove('loga');
      this.props.cookies.remove('loga1');
    }
    let account = instance;
    if (account === '') {
      account = 'poimapper';
    }
    void this.props.login({
      userName,
      password,
      instance: account
    }, rememberme, this.props.cookies);
  }

  @bind
  private useNormalLogin() {
    this.setState({ showPassAcc: true });
  }
  @bind
  private showAccount() {
    this.setState({ showAccount: true });
  }

  @bind
  private handleInputChange(e) {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  @bind
  private rememberMe(e) {
    const checked = e.target.checked;
    this.setState({ rememberme: checked });
  }

  @bind
  private displayResetPassword(visible: boolean) {
    this.setState({ resetPassword: visible });
  }

  @bind
  private getResetPasswordContent(): JSX.Element {
    const { resetPasswordError } =  this.state;
    return (
      <div className="form-group">
        <label htmlFor="username">
          Username or email address
        </label>
        <input
          type="text"
          className="form-control"
          id="resetEmail"
          name="resetEmail"
          placeholder="Enter username or email address"
          value={this.state.resetEmail}
          onChange={this.handleInputChange}
        />
        <span><small>{ 'If you do not receive instructions in a few minutes, please check your email\'s' +
         'junk and spam filters.' }</small></span>
        {resetPasswordError !== '' && (
          <div className="alert alert-danger" role="alert">
            {resetPasswordError}
          </div>
        )}
      </div>
    );
  }

  /*
    This function send the request to reset the password for the given username/email
  */
  @bind
  private resetPassword() {
    const { resetPassword } = this.props;
    const { resetEmail, account } = this.state;
    if (resetEmail === '') {
      this.setState({ resetPasswordError: 'Fill in the username/email.'});
      return;
    }
    void resetPassword(resetEmail.toLowerCase(), account || 'poimapper');
    this.setState({ resetPasswordError: '' } , () => this.displayResetPassword(false));
  }

  @bind
  private cancelModal() {
    this.setState({ resetPassword: false });
  }

  @bind
  private handleGoogleLogin(auth) {
    if (!auth.error) {
      void this.props.doGoogleLogin(auth.tokenId, this.props.cookies);
    }
  }

  private renderGoogleLoginButton(): JSX.Element {
    const manualLogout = this.props.cookies?.get('manualLogout');
    const automaticallyLogIn = !(manualLogout && manualLogout === 'true');
    return (
      <div className="form-group googleSignInDiv">
        <span className="googleSignInDiv__label">{getLocalization('signinwith')}</span>
        <GoogleLogin
          clientId="889223543345-g564lvsqo75nahnqsr349viskj4tpeok.apps.googleusercontent.com"
          buttonText="Google"
          responseType={'id_token'}
          uxMode={'popup'}
          onSuccess={this.handleGoogleLogin}
          onFailure={this.handleGoogleLogin}
          isSignedIn={automaticallyLogIn}
        />
      </div>
    );
  }

  public render(): JSX.Element {
    const { usernameError, passwordError, accountError, account, showPassAcc, fixedAccount, showAccount } = this.state;
    const { checkTwoFactorAuthCode } = this.props.clientPersist;
    const accountInput = showAccount === true && !fixedAccount ? (
      <div className={`form-group ${ accountError === true ? 'has-error' : '' }`}>
        <label className="control-label" htmlFor="username">
          {getLocalization('loginAccountName')}
        </label>
        <input
          type="text"
          className="form-control form-control-sm"
          id="databaseName"
          name="account"
          placeholder={getLocalization('accountPlaceholder')}
          value={this.state.account}
          onChange={this.handleInputChange}
        />
        <span><small>{getLocalization('defaultaccount')}</small></span>
      </div>
    ) : null;

    const resetPassword = this.state.resetPassword === true ? (
      <GenericModal
        visible={this.state.resetPassword}
        cancel={this.cancelModal}
        body={this.getResetPasswordContent()}
        title={getLocalization('resetpassword')}
        onConfirm={this.resetPassword}
        cancelText={getLocalization('cancel')}
        confirmText={getLocalization('resetpassword')}
      />
    ) : null;
    const signup = account === 'poimapper' ? (
      <div className="form-group">
        <span> {getLocalization('noaccount')} </span>
        <span className="separator">&nbsp;|&nbsp;</span>
        <a href={'register.jsp?c=poimapper&signup=t&src=portal'} title="Click to enter registration details.">
          {getLocalization('signup')}
        </a>&nbsp; {getLocalization('freetrial')}
      </div>
    ) : null;
    const accountDisplay = this.state.account !== '' ? (
      <div className="form-header">
        <strong>{getLocalization('connectedto')} </strong>: {this.state.account}
      </div>
    ) : null;
    const IEAlert = this.state.isIE ? (
      <h5 className="bg-danger text-danger text-center no-ie">
        {getLocalization('noIE')}
      </h5>
    ) : null;

    const passAcc = showPassAcc ? (
      <React.Fragment>
        <div className={`form-group`}>
          <label className="control-label" htmlFor="passkey">
            {getLocalization('password')}
          </label>
          <PasswordInput
            passwordError={passwordError}
            value={this.state.password}
            onChange={this.handleInputChange}
            disabled={this.state.isIE}
            name="password"
          />
        </div>
        {accountInput}
        <div className="form-group">
          <label>
            <input
              name="rememberme"
              id="rememberme"
              type="checkbox"
              checked={this.state.rememberme}
              onChange={this.rememberMe}
            />
            <span> {getLocalization('rememberme')}</span>
          </label>
          <span className="separator">|</span>
          <a href="#" className="forgot" onClick={() => this.displayResetPassword(true)}>
            {getLocalization('forgot')}
          </a>
        </div>
      </React.Fragment>
    ) : null;

    const changeAccountLink = !fixedAccount ? (
      <div className="form-group">
        <a href="#" onClick={this.showAccount}>
          {getLocalization('changeaccount')}
        </a>
      </div>
    ) : null;
    return (
      <div className={'LoginComponent'}>
        {checkTwoFactorAuthCode ? (
          <TwoFaComponent />
        ) : (
          <>
            {accountDisplay}
            {IEAlert}
            <form role="form" onSubmit={this.login}>
              <div className={`form-group`}>
                <label className="control-label" htmlFor="username">
                  {getLocalization('username')}
                </label>
                <input
                  type={'text'}
                  className={`form-control form-control-sm ${ usernameError === true ? 'is-invalid' : '' }`}
                  id="username"
                  name="username"
                  placeholder={getLocalization('enterUsername')}
                  value={this.state.username}
                  onChange={this.handleInputChange}
                  disabled={this.state.isIE}
                />
              </div>
              {passAcc ? passAcc : accountInput}
              <div className="form-group">
                <button
                  id="login-btn"
                  className="btn btn-primary btn-sm login-btn"
                  onClick={this.login}
                  disabled={this.state.isIE}
                >
                  {showPassAcc ? getLocalization('signin') : getLocalization('next')}
                </button>
              </div>
              {changeAccountLink}
              <div className="login-or">OR</div>
              {this.renderGoogleLoginButton()}
              {signup}
            </form>
            {resetPassword}
          </>
        )}
      </div>
    );
  }
}

export default withRouter(LoginComponentClass);
