import * as React from 'react';
import bind from 'bind-decorator';
import { uniq } from 'lodash-es';
import marked from 'marked';
import DOMPurify from 'dompurify';
import '../styles/TableQuestion.scss';
// import { Resizable } from 're-resizable';
import { Button, InputGroup } from 'react-bootstrap';
import uuid  from 'uuid/v4';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import { TableQuestionPropsInterface } from '../Containers/TableQuestionContainer';
import { createRequestFilters } from '../../../utils/utils';
import { getReferencedQuestions, getReferenceTableValue } from '../utils/TableQuestionUtils';
import { initDataPoint } from '../utils/utils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import FormUtils from '../utils/FormUtils';
import SingleInstanceChartComponent from '../../Charts/SingleInstanceChartComponent';
import SingleInstanceChartsSelector from '../../Charts/SingleInstanceChartsSelector';
import { getLocalization } from '../../../global/global';
import { renderQuestions } from '../utils/qnrenderer';
import { getFormUtils } from '../utils/FormUtilsHolder';
import RemoveRowBtn from './RemoveRowBtn';
import TableRow from './TableRow';
import TablePager from './TablePager';
import Transmit from './transmit';
import TextInputComponent from './TextInputComponent';
import OriginalFormText from './OriginalFormText';

interface State {
  value: DataPoint[];
  subFormDataPoints: DataPoint[];
  height: number | undefined;
  pageSize: number;
  page: number;
  search: string;
  filtered: DataPoint[];
}

const DEFAULT_PAGE_SIZE = 5;

export default class TableQuestion extends React.Component <TableQuestionPropsInterface, State> {
  private formUtils: FormUtils;
  private headerEl: JSX.Element | null = null;
  private subFormUtils: FormUtils | undefined = undefined;

  private abortController: AbortController = new AbortController();

  private sumFields: string[] = [];
  private avgFields: string[] = [];

  private divEl = React.createRef<HTMLDivElement>();

  private required = (
    <span className="text-danger">*</span>
  );

  constructor(props: TableQuestionPropsInterface) {
    super(props);
    const { dataPoint, question, tableForm, subForm } = this.props;
    this.formUtils = getFormUtils(tableForm);
    if (subForm) {
      this.subFormUtils = getFormUtils(subForm);
    }
    this.state = question.type === 'ReferenceTableQuestion' ? this.getReferencedSubformValues() : {
      value: dataPoint[question.id] ? dataPoint[question.id] : [],
      subFormDataPoints: [],
      height: undefined,
      pageSize: DEFAULT_PAGE_SIZE,
      page: 0,
      search: '',
      filtered: []
    };

    for (const qn of tableForm.question) {
      if (qn.operation === 'SUM') {
        this.sumFields.push(qn.id);
      } else if (qn.operation === 'AVERAGE') {
        this.avgFields.push(qn.id);
      }
    }
  }

  @bind
  private getReferencedSubformValues(): State {
    const { formUtils, dataPoint, question, tableForm, subForm, forms } = this.props;
    const oldValue = dataPoint[question.id] ? dataPoint[question.id] : [];
    const referencedTable = formUtils.getQuestion(question.value);
    if ((subForm && subForm.isChild) || referencedTable) {
      let subFormDataPoints: DataPoint[] = [];
      let referencedUtils = this.subFormUtils;
      if (referencedTable) {
        subFormDataPoints = dataPoint[question.value] || [];
        const formId = referencedTable.table?.columns.relation[0].ref;
        const form = forms.find(f => f.ref === formId);
        form?.referenceName = referencedTable.spssvariable;
        referencedUtils = getFormUtils(form);
      } else {
        const subFormQuestionIds = formUtils.getSubFormQuestionIds();
        subFormQuestionIds.forEach((id) => {
          if (dataPoint[id]) {
            const data = dataPoint[id];
            if (data.length > 0 && data[0].questionnaire_id === question.value) {
              subFormDataPoints = subFormDataPoints.concat(data);
            }
          }
        });
      }

      const value = getReferenceTableValue(
        question, referencedUtils, subFormDataPoints, oldValue, tableForm, formUtils
      );
      const { updateAnswer } = this.props;
      if (updateAnswer) {
        const newAns = {};
        newAns[question.id] = value;
        updateAnswer(newAns);
      }
      return {
        value,
        subFormDataPoints,
        height: undefined,
        pageSize: DEFAULT_PAGE_SIZE,
        page: 0,
        filtered: value,
        search: ''
      };
    } else {
      this.loadReferencedForm();
      return {
        value: oldValue,
        subFormDataPoints: [],
        height: undefined,
        pageSize: DEFAULT_PAGE_SIZE,
        page: 0,
        filtered: oldValue,
        search: ''
      };
    }
  }

  @bind
  private loadReferencedForm() {
    const {
      question, fetchPOI, subForm, filtersMenu, locationHierarchyQuery, tableForm, dataPoint, formUtils
    } = this.props;
    if (subForm) {
      let skipFilter = false;
      const fields = getReferencedQuestions(tableForm, this.subFormUtils);
      fields.push('id');
      this.createAbortController();
      const filters = createRequestFilters(filtersMenu);
      if (question.script && question.script !== '') {
        const allVariables: string[] = uniq(question.script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g));
        const tempFilter = {
          field: '',
          value: '',
          condition: '',
          operator: 1
        };
        let script = question.script;
        script = script.substring(script.indexOf('(') + 1, script.lastIndexOf(')')).trim();

        for (const variable of allVariables) {
          script = script.replace(variable, '');
          if (variable.startsWith(`_${subForm.referenceName}`)) {
            const refName = variable.substring(variable.indexOf('.') + 1);
            const questionId = this.subFormUtils?.getQuestionId(`_${refName}`);
            if (questionId) {
              const qn = this.subFormUtils?.getQuestion(questionId);
              if (qn && qn.type !== questionTypes.SELECT_MULTIPLE_QUESTION
                && qn.type !== questionTypes.SELECT_ONE_QUESTION
                && qn.type !== questionTypes.LIKERT_SCALE_QUESTION) {
                tempFilter.field = questionId;
              } else {
                if (fields.indexOf(questionId) === -1) {
                  fields.push(questionId);
                }
                skipFilter = true;
              }
            }
          } else {
            const questionId = formUtils.getQuestionId(variable);
            tempFilter.value = dataPoint[questionId];
          }
        }
        const values = script.match(/\'[a-zA-Z0-9]*\'|\"[a-zA-Z0-9]*\"/g);
        if (values) {
          script = script.replace(values[0], '').trim();
          tempFilter.value = values[0].replace(/\'|\"/g, '');
        }
        // const scriptParts = script.trim().split(' ');
        switch (script.trim()) {
          case '==':
            tempFilter.condition = 'EQUAL';
            break;
          case '>=':
            tempFilter.condition = 'GREATER_THAN_OR_EQUAL';
            break;
          case '<=':
            tempFilter.condition = 'LESS_THAN_OR_EQUAL';
            break;
          case '!=':
            tempFilter.condition = 'NOT_EQUAL';
            break;
          case '>':
            tempFilter.condition = 'GREATER_THAN';
            break;
          case '<':
            tempFilter.condition = 'LESS_THAN';
            break;
          default:
            tempFilter.condition = 'EQUAL';
        }
        if (!skipFilter) {
          filters.push(tempFilter);
        }
      }
      const request = fetchPOI(
        subForm.ref, this.abortController.signal, undefined, filters, locationHierarchyQuery, fields.join(',')
      );
      this.poisLoaded(request);
    }
  }

  @bind
  private poisLoaded(requestPromise: Promise<Response>) {
    const { dataPoint, question, tableForm, formUtils } = this.props;

    requestPromise.then(response => response.json()).then((json) => {
      if (json && json.length > 0) {
        const oldValue = dataPoint[question.id] ? dataPoint[question.id] : [];
        const value = getReferenceTableValue(
          question, this.subFormUtils, json, oldValue, tableForm, formUtils, dataPoint
        );
        const { updateAnswer } = this.props;
        if (updateAnswer) {
          const newAns = {};
          newAns[question.id] = value;
          updateAnswer(newAns);
        }
        this.setState({ value, subFormDataPoints: json, filtered: value });
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  @bind
  private createAbortController() {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
  }

  /*
    For dynamic tables, removes a row.
  */
  @bind
  private removeRow(i: number) {
    const { value } = this.state;
    const newValue = Object.assign([], value);
    newValue.splice(i, 1);
    this.setState({ value: newValue }, this.updateMainPOI);
  }

  /*
    For dynamic tables, adds a new row.
  */
  @bind
  private addRow() {
    const { value } = this.state;
    const { tableForm } = this.props;
    const newValue = Object.assign([], value);
    const newRow = initDataPoint({}, tableForm);
    newRow.id = `DV-${uuid()}`;
    newValue.push(newRow);
    this.setState({ value: newValue }, this.updateMainPOI);
  }

  /*
    Updates the main POI value.
  */
  @bind
  private updateMainPOI() {
    const { value } = this.state;
    const { question, updateAnswer } = this.props;
    if (updateAnswer) {
      const newAns = {};
      newAns[question.id] = value;
      updateAnswer(newAns);
    }
  }

  @bind
  public updateAnswer(answer: DataPoint, index: number) {
    this.setState((prevState) => {
      const { value, filtered } = prevState;
      const { question } = this.props;
      if (question.type === questionTypes.REFERENCE_TABLE_QUESTION) {
        const newFiltered = [...filtered];
        newFiltered.splice(index, 1, answer);
        const newValue = value.map(v => {
          if (v.subformID && v.subformID === answer.subformID) {
            return answer;
          }
          return v;
        });
        return { value: newValue, filtered: newFiltered };
      } else {
        const newValue = [...value];
        newValue.splice(index, 1, answer);
        return { value: newValue, filtered: [] };
      }
    }, () => {
      this.updateMainPOI();
      if (this.state.search) {
        this.filterTable();
      }
    });
  }

  /*
    Returns the header for the table.
  */
  @bind
  private getHeader(): JSX.Element {
    if (this.headerEl) {
      return this.headerEl;
    }
    const { tableForm, question, formUtils } = this.props;
    const headers = [<th key={`${question.id}_${tableForm.id}`} className="counter">#</th>];

    let nameHeader = 'Name';
    let nameWidth = -1;
    for (const qn of tableForm.question) {
      if ('Name' === qn.id) {
        nameHeader = qn.text;
        nameWidth = qn.columnWidth;
      }
      if (!qn.hideInSingleInstance && !qn.inVisible && !qn.deleted && qn.id !== 'subformID') {
        const style = qn.columnWidth > 0 ? { minWidth: `${qn.columnWidth}px`} : {};
        headers.push(
          <th
            key={`${question.id}_${tableForm.id}_${qn.id}`}
            style={style}
          >
            {qn.text ? qn.text : ''}
            {qn.optional ? null : this.required}
            <OriginalFormText formId={formUtils.getModel().ref} viewId={qn.id} />
          </th>
        );
      }
    }
    if (question.table.rows && question.table.rows.listItem) {
      headers.splice(1, 0, (
        <th
          key={`${question.id}_${tableForm.id}_name`}
          style={nameWidth > -1 ?  { width: `${nameWidth}px`} : {}}
          className={nameWidth > 0 ? 'custom-width' : ''}
        >
          {nameHeader}
        </th>
      ));
    } else {
      headers.push(<th key={`${question.id}_${tableForm.id}_remove`} className="remove">{}</th>);
    }
    this.headerEl = (
      <tr>{headers}</tr>
    );
    return this.headerEl;
  }

  @bind
  private getVisibleQuestions() {
    const { tableForm } = this.props;
    const questions: QuestionInterface[] = [];
    for (const qn of tableForm.question) {
      if (!qn.hideInSingleInstance && !qn.inVisible && !qn.deleted && qn.id !== 'subformID') {
        questions.push(qn);
      }
    }
    return questions;
  }

  /*
    This function returns the static table rows.
    If the row has no data it would initialize a new data model which would be passed to the questions.
    It also adds the row name as a column after the count column.
  */
  @bind
  private getStaticRows(): JSX.Element[] {
    const { tableForm, question, forms, clientPersist, disabled, dataPoint, formUtils } = this.props;
    const { value } = this.state;
    const rows: JSX.Element[] = [];
    let i = 0;
    for (const rowItem of question.table.rows.listItem) {
      let rowValue = value.find( v => {
        return v.Name === rowItem.id || v.Name === rowItem.value;
      });
      if (rowValue && !rowValue.id) {
        rowValue.id = uuid();
      }
      if (!rowValue && question.table.rows.listItem.length === value.length) {
        rowValue = question.table.rows.listItem[i];
      }
      if (!rowValue) {
        const tempDataPoint: DataPoint = { Name: rowItem.id ? rowItem.id : rowItem.value, id : uuid() };
        rowValue = initDataPoint(tempDataPoint, tableForm);
      }
      const row = (
        <TableRow
          key={`${question.id}_${tableForm.id}_row_${i}`}
          dataPoint={rowValue}
          dynamic={false}
          formUtils={this.formUtils}
          rowItem={rowItem}
          updateAnswer={this.updateAnswer}
          index={i}
          question={question}
          forms={forms}
          clientPersist={clientPersist}
          disabled={disabled}
          parentModel={formUtils.getModel()}
          parentDataPoint={dataPoint}
        />
      );
      rows.push(row);
      i++;
    }
    return rows;
  }

  /*
    returns the dynamic table rows for this table question.
  */
  @bind
  private getDynamicTableRows(): JSX.Element[] {
    const { tableForm, question, forms, clientPersist, disabled, dataPoint, formUtils } = this.props;
    if (question.fieldsAsRows) {
      return this.getFieldsAsRows();
    }
    const { value } = this.state;
    const rows: JSX.Element[] = [];
    let i = 0;
    if (value.length === 0) {
      value[0] = initDataPoint({}, tableForm);
      value[0].id = uuid();
    }
    for (const val of value) {
      const row = (
        <TableRow
          key={`${question.id}_${tableForm.id}_row_${val.id}`}
          dataPoint={val}
          dynamic
          formUtils={this.formUtils}
          removeRow={this.removeRow}
          rowItem={undefined}
          updateAnswer={this.updateAnswer}
          index={i}
          question={question}
          forms={forms}
          clientPersist={clientPersist}
          disabled={disabled}
          parentModel={formUtils.getModel()}
          parentDataPoint={dataPoint}
        />
      );
      rows.push(row);
      i++;
    }
    return rows;
  }

  @bind
  private getFieldsAsRows() {
    const { tableForm, question, forms, clientPersist, disabled, dataPoint, formUtils } = this.props;
    const { value } = this.state;

    if (value.length === 0) {
      value[0] = initDataPoint({}, tableForm);
      value[0].id = uuid();
    }
    const rows: JSX.Element[] = [];

    const updateAnswer = (val: DataPoint, index: number) => {
      this.updateAnswer({...value[index], ...val}, index);
    };

    const questions: QuestionInterface[] = this.getVisibleQuestions();

    const sums = {};
    this.sumFields.forEach((id) => { sums[id] = 0; });
    this.avgFields.forEach((id) => { sums[id] = 0; });

    for (const val of value) {
      this.sumFields.forEach((id) => { sums[id] += val[id] || 0; });
      this.avgFields.forEach((id) => { sums[id] += val[id] || 0; });
    }

    const hasSumAvg = this.sumFields.length > 0 || this.avgFields.length > 0;

    for (const q of questions) {
      const row = (
        <tr key={`${question.id}_${tableForm.id}_row_${q.id}`}>
          <td className={'field-as-rows-header'}>
            {q.text ? q.text : null}
            {q.optional ? null : this.required}
          </td>
          {value.map((v, index) => {
            return renderQuestions(
              [q], v, true, forms, (val) => updateAnswer(val, index), formUtils,
              false, clientPersist, formUtils.getModel(), dataPoint, question, undefined, disabled
            );
          })}
          {hasSumAvg ? (
            <td className={'field-as-rows-operation'}>
              {this.sumFields.indexOf(q.id) > -1 ? `${getLocalization('sum')} : ${sums[q.id]}` : null}
              {this.avgFields.indexOf(q.id) > -1 ?
                `${getLocalization('average')} : ${sums[q.id] / value.length}` : null}
            </td>
          ) : null}
        </tr>
      );
      rows.push(row);
    }
    rows.push((
      <tr key={`${question.id}_${tableForm.id}_remove_row`}>
        <td />
        {value.map((v, index) => (
          <td key={`${question.id}_${tableForm.id}_remove_row_${v.id}`}>
            <RemoveRowBtn
              removeRow={this.removeRow}
              fieldsAsRows={true}
              index={index}
            />
          </td>
        ))}
      </tr>
    ));
    return rows;
  }

  @bind
  private getReferenceTableRows(): JSX.Element[] {
    const { tableForm, question, forms, clientPersist, dataPoint, formUtils } = this.props;
    const { subFormDataPoints, page, pageSize, filtered } = this.state;
    const rows: JSX.Element[] = [];
    let i = 0;

    for (const subFormDataPoint of subFormDataPoints) {
      const tableData = filtered.find( val => val.subformID === subFormDataPoint.id);
      if (tableData) {
        const row = (
          <TableRow
            key={`${question.id}_${tableForm.id}_row_${tableData.subformID}`}
            dataPoint={tableData}
            dynamic
            formUtils={this.formUtils}
            rowItem={undefined}
            updateAnswer={this.updateAnswer}
            index={i}
            question={question}
            forms={forms}
            clientPersist={clientPersist}
            parentModel={formUtils.getModel()}
            parentDataPoint={dataPoint}
          />
        );
        rows.push(row);
        i++;
      }
    }
    if (pageSize === -1) {
      return rows;
    }
    const start = page * pageSize;
    return rows.slice(start, start + pageSize);
  }

  @bind
  private getOperationsFooter(): JSX.Element | null {
    if (this.sumFields.length === 0 && this.avgFields.length === 0) {
      return null;
    } else {
      const { tableForm, question } = this.props;
      const { value } = this.state;
      const footers = [<td key={`${question.id}_${tableForm.id}_f`}/>];

      const sums = {};
      this.sumFields.forEach((id) => { sums[id] = 0; });
      this.avgFields.forEach((id) => { sums[id] = 0; });

      for (const val of value) {
        this.sumFields.forEach((id) => { sums[id] += val[id] || 0; });
        this.avgFields.forEach((id) => { sums[id] += val[id] || 0; });
      }

      for (const qn of tableForm.question) {
        if (!qn.hideInSingleInstance && !qn.inVisible && !qn.deleted && qn.id !== 'subformID') {
          let content;
          if (this.sumFields.indexOf(qn.id) !== -1) {
            content = 'Sum: ' + sums[qn.id];
          } else if (this.avgFields.indexOf(qn.id) !== -1) {
            content = 'Average: ' + sums[qn.id] / value.length;
          } else {
            content = '';
          }
          footers.push(
            <td key={`${question.id}_${tableForm.id}_${qn.id}_f`}>
              {content}
            </td>
          );
        }
      }
      return <tfoot><tr>{footers}</tr></tfoot>;
    }
  }

  @bind
  private getChartComponents() {
    const { question, tableForm, dataPoint } = this.props;
    if (question.type === questionTypes.TABLE_QUESTION && question.table.rows &&
      dataPoint.row_id && dataPoint.row_id > 0) {
      return [(
        <SingleInstanceChartComponent
          key={`single-instance-chart-component`}
          formId={tableForm.ref}
          rowId={dataPoint.row_id}
        />
      ), (
        <SingleInstanceChartsSelector
          key={`single-instance-chart-selector`}
          formId={tableForm.ref}
        />
      )];
    }
    return null;
  }

  @bind
  private filterTable() {
    const { value, search } = this.state;
    if (search) {
      const filtered = value.filter(v => {
        const keys = Object.keys(v);
        for (const key of keys) {
          if (key !== 'subformID' && key !== 'questionnaire_id' && `${v[key]}`.search(search) > -1) {
            return true;
          }
        }
        return false;
      });
      this.setState({ filtered, page: 0 });
    } else {
      this.setState({ filtered: value, page: 0 });
    }
  }

  @bind
  private getSearchBox() {
    const { question } = this.props;
    const { search } = this.state;
    return question.type === questionTypes.REFERENCE_TABLE_QUESTION ? (
      <InputGroup className="col-3">
        <TextInputComponent
          onChange={(v) => this.setState({ search: `${v}`}, this.filterTable)}
          name={'search-subform'}
          value={search}
          placeholder={getLocalization('search')}
          extraClass={'form-control-sm'}
        />
        <InputGroup.Append>
          <Button size="sm" onClick={this.filterTable}>
            <i className="fa fa-search"/>
          </Button>
        </InputGroup.Append>
      </InputGroup>
    ) : null;
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public componentDidMount() {
    const { question, dataPoint } = this.props;
    const hash = window.location.hash.split('/');
    if (question.actionPerRow && hash[4] === question.id && dataPoint.row_id && this.divEl.current) {
      const topPos = this.divEl.current.offsetTop - 100;
      window.scroll(0, topPos);
    }
  }

  public render(): JSX.Element | null {
    const {
      question, users, clientPersist, sendActionEmail, dataPoint, tableForm, getSharerEmail, formUtils
    } = this.props;
    const { page, pageSize, value } = this.state;
    const rowId = (dataPoint.row_id || dataPoint._id);
    const rows = question.table.rows && question.table.rows.listItem ? this.getStaticRows() :
      question.type === questionTypes.REFERENCE_TABLE_QUESTION ?
        this.getReferenceTableRows() : this.getDynamicTableRows();
    //  :
    const addRowBtn = question.type === 'TableQuestion' && !question.table.rows && !this.props.disabled ? (
      <button className="btn btn-primary btn-sm add-row" onClick={this.addRow}>
        {getLocalization(question.fieldsAsRows ? 'addColumn' : 'addRow')}
      </button>
    ) : null;
    if (question.hideInSingleInstance) {
      return (
        <div className={'d-none'}>{rows}</div>
      );
    }
    const charts = this.getChartComponents();
    const text =  DOMPurify.sanitize(marked.parse(question.text));
    return (
      <div
        className="form-group col-md-12 col-lg-12 col-sm-12"
        ref={this.divEl}
      >
        {charts && charts[0]}
        <label className={'w-100 question-label'}>
          <span
            className={'mr-2'}
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
          <OriginalFormText formId={formUtils.getModel().ref} viewId={question.id} />
          {addRowBtn}
          {charts && charts[1]}
          {question.actionPerRow && rowId && (
            <Transmit
              users={users}
              clientPersist={clientPersist}
              link={`${dataPoint.questionnaire_id}/${dataPoint.id}/${rowId}/false/${question.id}`}
              questionId={question.id}
              tableFormId={tableForm.ref}
              id={dataPoint.id || ''}
              getSharerEmail={getSharerEmail}
              sendActionEmail={sendActionEmail}
            />
          )}
          {this.getSearchBox()}
        </label>
        {/* <Resizable
          size={{
            width: '100%',
            height: height || 'auto'
          }}
          className={`table-question-resizeable`}
          minHeight={'20vh'}
          minWidth={'100%'}
          enable={{
            top:false, right:false, bottom:true, left:false,
            topRight:false, bottomRight:false, bottomLeft:false, topLeft:false
          }}
          style={{
            borderBottom: '2px solid #ddd',
            overflow: 'initial'
          }}
          handleStyles={{
            bottom: {
              backgroundColor: '#ddd',
              height: '3px',
              width: '100%'
            }
          }}
          onResizeStop={(e, direction, ref, d) => {
            const h = this.state.height;
            const currentHeight = h ? h : Number(ref.clientHeight);
            this.setState({ height: currentHeight + d.height});
          }}
        >*/}
        <div className={'table-question-rows'}>
          <table className="table table-hover table-condensed">
            {!question.fieldsAsRows && (
              <thead>
                {this.getHeader()}
              </thead>
            )}
            <tbody>
              {rows}
            </tbody>
            {!question.fieldsAsRows && this.getOperationsFooter()}
          </table>
        </div>
        {/* </Resizable>*/}
        {question.type === 'ReferenceTableQuestion' && (
          <TablePager
            page={page}
            pageSize={pageSize}
            total={value.length}
            onChangePageSize={(v) => this.setState({ pageSize: v })}
            onChangePage={(v) => this.setState({ page: v })}
          />
        )}
      </div>
    );
  }
}
