import { difference, pick } from 'lodash-es';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { Locations } from '../../../Interfaces/LocationInterface';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { isNullOrUndefined } from '../../../utils/utils';
import { QuestionInterface, questionTypes } from '../../../Interfaces/Forms/QuestionInterface';
import FormUtils from './FormUtils';
import { getOptionCode, getOptionTexts } from './utils';
import { getFormUtils } from './FormUtilsHolder';

/**
 * This function goes through a given form JSON and returns the Question with the given spssVariable.
 * @spssVariable - The reference name (SPSS Variable) being checked.
 * @form: The form JSON to be checked.
 */
export const getQuestionFromForm = (spssVariable: string, form: FormInterface): LooseObject | undefined => {
  const getQuestion = (spssVariable: string, questions: QuestionInterface[]): QuestionInterface | undefined => {
    for (const question of questions) {
      if (question.id === 'Name' && spssVariable === 'name') {
        return question;
      }
      if (question.id === 'countQuestion' && spssVariable === 'count') {
        return question;
      }
      if (!question.inVisible && !question.deleted) {
        if (question.spssvariable === spssVariable) {
          return question;
        }
        if (question.type === questionTypes.LAYOUT_TABLE_QUESTION && question.table) {
          const columns = question.table.columns;
          if (columns && columns.column) {
            let q;
            columns.column.some((col) => {
              const questions = col.question;
              questions.some(qn => {
                if (!qn.deleted && !qn.inVisible) {
                  if (qn.spssvariable === spssVariable) {
                    q = qn;
                    return true;
                  }
                }
                return;
              });
              if (q) {
                return true;
              }
              return;
            });
            if (q) {
              return q;
            }
          }
        }
        if (question.triggerValues && question.triggerValues.triggerValue) {
          for (const triggerValue of question.triggerValues.triggerValue) {
            if (triggerValue.action.subQuestions) {
              const qn = getQuestion(spssVariable, triggerValue.action.subQuestions.question || []);
              if (qn) {
                return qn;
              }
            }
          }
        }
      }
    }
    return undefined;
  };

  if (form.questionPage) {
    for (const page of form.questionPage) {
      const question = getQuestion(spssVariable, page.question || []);
      if (question) {
        return question;
      }
    }
  }

  if (form.question) {
    return getQuestion(spssVariable, form.question);
  }
  return undefined;
};

/**
 * Given an array of questions and id, this function returns the question with the given id.
 * returns undefined if the question is not found.
 * @questions - Array of questions to be checked.
 * @id - The id of the question being searched.
 */
export const getQuestion = (questions: LooseObject[], id: string): LooseObject | undefined => {
  for (const question of questions) {
    if (!question.inVisible && !question.deleted) {
      if (question.id === id) {
        return question;
      }
      if (question.triggerValues && question.triggerValues.triggerValue) {
        for (const triggerValue of question.triggerValues.triggerValue) {
          if (triggerValue.action.subQuestions) {
            const qn = getQuestion(triggerValue.action.subQuestions.question, id);
            if (qn) {
              return qn;
            }
          }
        }
      }
    }
  }
  return undefined;
};

/**
 * Given a form Object, this function would find the question with the given id.
 * @id - The id of the question to be searched.
 * @form - The form JSON
 */
export const getQuestionFromId = (id: string, form: LooseObject): LooseObject | undefined => {
  if (form.questionPage) {
    for (const page of form.questionPage) {
      const question = getQuestion(page.question || [], id);
      if (question) {
        return question;
      }
    }
  }
  return undefined;
};

/**
 * This function returns an array of ids for Subform Questions in a given page or the whole form.
 * If there is no Part of Question in the page where the Subform ref question is, it would return all the
 * ids of the Part of Questions that make use the Subform with the given Subform identifier.
 *
 * @form - The form to be checked.
 * @id - The id of the Calculated Value Question.
 * @subFormRef - The alpha numeric id of the Subform form.
 */
export const getPageSubForms = (form: LooseObject, id: string, subFormRef: string): string[] | null => {
  let currentPageSubforms: string[] = [];
  const allSubforms: string[] = [];

  /**
   * Function to loop through the questions.
   */
  let foundQuestion = false;
  const checkQuestions = (questions): boolean => {
    for (const qn of questions) {
      if (!qn.inVisible && !qn.deleted) {
        if (qn.type === 'Part_of_Question' || qn.type === 'TaskQuestion') {
          if (subFormRef === qn.listItems.relation[0].ref) {
            currentPageSubforms.push(qn.id);
            allSubforms.push(qn.id);
          }
        }
        if (qn.id === id) {
          foundQuestion = true;
        }
        if (qn.triggerValues && qn.triggerValues.triggerValue) {
          for (const triggerValue of qn.triggerValues.triggerValue) {
            if (triggerValue.action.subQuestions) {
              checkQuestions(triggerValue.action.subQuestions.question || []);
            }
          }
        }
      }
    }
    return false;
  };

  if (form.questionPage) {
    for (const page of form.questionPage) {
      currentPageSubforms = [];
      foundQuestion = false;
      checkQuestions(page.question || []);
      // if we find the question and the current page has subforms, return the current page subforms.
      if (foundQuestion) {
        if (currentPageSubforms.length > 0) {
          return currentPageSubforms;
        }
      }
    }
  }
  // here we return all the subform ids.
  if (allSubforms.length > 0) {
    return allSubforms;
  }
  return null;
};

/**
 * Returns sum of values in the child/table form.
 * if values are numeric they will be summed.
 * If values are string they will be concatenated.
 */
export const sumTableSubformValues = (
  subFormRef: string,
  form: FormInterface,
  values: LooseObject[],
): string | number => {
  const formUtils = getFormUtils(form);
  const qnId = formUtils.getQuestionId(`_${subFormRef}`);
  const question = qnId ? formUtils.getQuestion(qnId) : getQuestionFromForm(subFormRef, form);
  if (question) {
    const tempValues: any = [];
    for (const val of values) {
      let colValue: number | string = '';
      if (
        question.type === 'SelectOneQuestion' ||
        question.type === 'SelectMultipleQuestion' ||
        question.type === 'LikertScaleQuestion'
      ) {
        colValue = val[question.id] ? getOptionCode(question, val[question.id].split(',')) : '';
      } else {
        colValue = getQuestionValue(question, val);
      }
      if (isNullOrUndefined(colValue) && question.default) {
        colValue = question.default;
        if (isNumeric(question)) {
          colValue = Number(colValue);
        }
      }
      if (!isNullOrUndefined(colValue)) {
        tempValues.push(colValue);
      }
    }
    if (isNumeric(question)) {
      return tempValues.length > 0 ? tempValues.reduce((accum, value) => Number(accum) + Number(value), 0) : 0;
    } else {
      return tempValues.join(',');
    }
  }
  return '';
};

export const isNumeric = (question) => {
  return question.type === 'IntQuestion' ||
        question.type === 'FloatQuestion' ||
        (question.type === 'CalculatedValueQuestion' && question.numericOnly)
    ? true
    : false;
};

/**
 * Returns the value being referred to by lookup.
 */
export const getLookupValue = (
  reference: string,
  value: string,
  qn: LooseObject,
  lookupOptions: LooseObject,
  forms: LooseObject,
  vars: any,
  pois?: LooseObject,
  locationHierarchy?: Locations,
): string | number => {
  if (value && lookupOptions) {
    const row = lookupOptions[value];
    if (!row) {
      return '';
    }
    const headers = lookupOptions['$headers'];
    const header = reference.substr(reference.indexOf('.') + 1, reference.length);
    const ref = reference.substring(0, reference.indexOf('.'));
    const index = headers.indexOf(header);
    const lookupValue = row[index] ? row[index].trim() : '';
    let newItem = lookupValue;
    if (lookupValue.charAt(0) === '\'' && lookupValue.charAt(lookupValue.length - 1) === '\'') {
      newItem = lookupValue.substring(1, lookupValue.length - 1);
    }
    const val = newItem.replace(/\'/gi, '\\\'').replace(/"/gi, '\\\'');
    vars.push(`var ${ref} = ${ref} || {}; ${ref}.${header} = "${val}";`);
    return lookupValue;
  }
  if (!lookupOptions && pois) {
    const dataPoints = pois[qn.lookupValue];
    if (dataPoints && dataPoints.length > 0) {
      const dataPoint = dataPoints.find((dp) => dp.id === value);
      if (dataPoint) {
        const locations = ['location1', 'location2', 'location3', 'location4'];
        const mainRef = reference.substring(0, reference.indexOf('.'));
        const ref = reference.substr(reference.indexOf('.') + 1, reference.length);
        const form = forms.find((f) => f.ref === qn.lookupValue);
        if (form) {
          if (locations.indexOf(ref) !== -1) {
            const qnValue = getLocationHierarchy('', locationHierarchy, dataPoint[ref], []);
            vars.push(`var ${mainRef} = ${mainRef} || {}; ${mainRef}.${ref} = "${qnValue}";`);
            return qnValue;
          } else {
            const refQn = getQuestionFromForm(ref, form);
            if (refQn && dataPoint[refQn.id]) {
              const qnValue = getQuestionValue(refQn, dataPoint);

              vars.push(`var ${mainRef} = ${mainRef} || {}; ${mainRef}.${ref} = "${qnValue}";`);
              return qnValue;
            }
          }
        }
      }
    }
  }
  return '';
};

/**
 * Given a question and data point object, this function returns the value.
 * For select questions (select, multiple, likert, boolean) this function returns the option text.
 */
export const getQuestionValue = (qn: LooseObject, dataPoint: DataPoint): any => {
  if (qn.type === 'SelectOneQuestion' || qn.type === 'SelectMultipleQuestion' || qn.type === 'LikertScaleQuestion') {
    return dataPoint[qn.id] ? getOptionTexts(qn, dataPoint[qn.id].split(',')) : '';
  } else if (qn.type === 'BooleanQuestion') {
    return dataPoint[qn.id] === true ? 'Yes' : dataPoint[qn.id] === false ? 'No' : '';
  }
  return dataPoint[qn.id];
};

/**
 * Thid function returns the values from the Parent DataPoint
 * @key - The script being processed.
 * @parenrModel - The Parent form JSON.
 * @parentDataPoint - The parent @{link DataPoint} object
 * @vars - String for variable initialization.
 */
export const getParentValues = (
  key: string, parentModel: FormInterface, parentDataPoint: DataPoint, vars: any
): any => {
  const parentRef = key.endsWith('.text')
    ? key.substring(key.indexOf('.') + 1, key.indexOf('.text'))
    : key.substr(key.indexOf('.') + 1, key.length);
  if (parentModel && parentDataPoint) {
    const parentFormUtils = getFormUtils(parentModel);
    const qnId = parentFormUtils.getQuestionId(`_${parentRef}`);
    const parentQn = parentFormUtils.getQuestion(qnId); // getQuestionFromForm(parentRef, parentModel);
    if (parentQn) {
      let dpValue = parentDataPoint[parentQn.id];
      switch (parentQn.type) {
        case questionTypes.SELECT_ONE_QUESTION:
        case questionTypes.SELECT_MULTIPLE_QUESTION:
        case questionTypes.LIKERT_SCALE_QUESTION:
          dpValue = getOptionCode(parentQn, dpValue);
          break;
        default:
          dpValue = parentDataPoint[parentQn.id];
      }
      const value = key.endsWith('.text') ? parentQn.text : dpValue;
      let varPart = 'var _parent = _parent || {};';
      let valueStr = '';
      if (typeof value === 'number') {
        valueStr += `${value}`;
      } else {
        valueStr += `"${value}";`;
      }

      if (key.endsWith('.text')) {
        varPart += ` _parent.${parentRef} = {}; _parent.${parentRef}.text = ${valueStr}`;
      } else {
        varPart += ` _parent.${parentRef} = ${valueStr};`;
      }
      vars.push(varPart);
      return value;
    }
  }
  return undefined;
};

export const getChildValues = (
  qn: LooseObject,
  id: string,
  key: string,
  dataPoint: DataPoint,
  forms: LooseObject,
  vars: any,
): any => {
  const values = dataPoint[id];
  const formRef =
        qn.type === 'TableQuestion' || qn.type === 'ReferenceTableQuestion'
          ? qn.table.columns.relation[0].ref
          : qn.listItems.relation[0].ref;
  const form = forms.find((f) => f.ref === formRef);
  const qnRef = key.substr(key.indexOf('.') + 1, key.length);
  if (values) {
    if (form) {
      const value = sumTableSubformValues(qnRef, form, values);
      const parts = key.split('.');
      if (parts.length === 2) {
        let varPart = `var ${parts[0]} = ${parts[0]} || {};`;
        if (typeof value === 'number') {
          varPart += `${parts[0]}.${parts[1]} = ${value};`;
        } else {
          varPart += `${parts[0]}.${parts[1]} = "${value}";`;
        }
        vars.push(varPart);
      }
      return value;
    }
  }
  /* const parts = key.split('.');
  const question = getQuestionFromForm(qnRef, form);
  if (question) {
    const value = question.default ?
      (isNumeric(question) ? Number(question.default) : question.default)
      : isNumeric(question) ? 0 : '';
    if (parts.length === 2) {
      let varPart = `var ${parts[0]} = ${parts[0]} || {};`;
      if (typeof value === 'number') {
        varPart += `${parts[0]}.${parts[1]} = ${value};`;
      } else {
        varPart += `${parts[0]}.${parts[1]} = "${value}";`;
      }
      vars.push(varPart);
    }
  }*/
  return undefined;
};

/*
  This function sums the values of a question in a subform.
  It will get all the subform instances in the given page.
*/
export const getSubFormValues = (ref, subForm, qn, dataPoint, mainForm, vars) => {
  const subFormQuestionIds = getPageSubForms(mainForm, qn.id, subForm.ref);
  const subQnRef = ref.substring(ref.indexOf('.') + 1, ref.length);
  const subQn = getQuestionFromForm(subQnRef, subForm);

  if (subFormQuestionIds && subQn) {
    const numeric = isNumeric(subQn);
    let sum: string | number = numeric ? 0 : '';
    for (const sqId of subFormQuestionIds) {
      let subforms = dataPoint[sqId];
      if (subforms) {
        if (qn.filterCondition) {
          subforms = filterSubforms(qn.filterCondition, subForm, subforms, mainForm, dataPoint);
        }
        for (const sf of subforms) {
          let value: number | string = '';
          if (
            subQn.type === 'SelectOneQuestion' ||
            subQn.type === 'SelectMultipleQuestion' ||
            subQn.type === 'LikertScaleQuestion'
          ) {
            value = sf[subQn.id] ? getOptionCode(subQn, sf[subQn.id].split(',')) : '';
          } else {
            value = getQuestionValue(subQn, sf);
          }
          // const value = getQuestionValue(subQn, sf);
          if (value) {
            if (numeric) {
              sum = Number(sum) + Number(value);
            } else {
              sum = sum !== '' ? `${sum},${value}` : value;
            }
          }
        }
      }
    }
    const refPart = ref.substring(0, ref.indexOf('.'));
    if (numeric) {
      vars.push(`var ${refPart} = ${refPart} || {}; ${refPart}.${subQnRef} = ${sum};`);
    } else {
      vars.push(
        `var ${refPart} = ${refPart} || {}; ${refPart}.${subQnRef} = "${`${sum}`.replace(/\n/g, '\\n')}";`,
      );
    }
    return sum;
  }
  return 0;
};

export const getSelectValues = (
  formUtils: FormUtils,
  id: string,
  currentValue: any,
  value: any,
  qn: LooseObject,
  key: string,
  vars,
): any => {
  if (currentValue && value) {
    currentValue = formUtils.getOptionIds(id, value.split(','));
    if (difference(currentValue.split(','), value.split(',')).length > 0) {
      if (key.endsWith('.text')) {
        value = getOptionTexts(qn, value.split(','));
      } else {
        value = formUtils.getOptionCodes(id, value.split(','));
      }
    }
  } else if (value) {
    if (key.endsWith('.text')) {
      value = getOptionTexts(qn, value.split(','));
    } else {
      value = formUtils.getOptionCodes(id, value.split(','));
    }
  }
  if (!value && qn.default) {
    if (key.endsWith('.text')) {
      value = getOptionTexts(qn, qn.default.split(','));
    } else {
      value = formUtils.getOptionCodes(id, qn.default.split(','));
    }
  }
  const keys = key.split('.');
  if (!value) {
    value = '';
  }
  if (keys.length === 1) {
    vars.push(`var ${key} = "${value}";`);
  } else {
    let varPart = `var ${keys[0]} = ${keys[0]} || {};`;
    if (keys[1] === 'text') {
      varPart += `${keys[0]}.text = "${value}";`;
    } else {
      varPart += `${keys[0]} = "${value}";`;
    }
    vars.push(varPart);
  }
  return value;
};

export const getLocationHierarchy = (key, locationHierarchy, value, vars): string => {
  const location = locationHierarchy.find((loc) => {
    return loc.key === value || `${loc.key}` === `${value}`;
  });
  if (location) {
    value = location.title;
    vars.push(`var ${key} = "${value}";`);
    return value;
  }
  return value;
};

export const getPostalCode = (key: string, dataPoint: DataPoint, locationHierarchy: Locations): string => {
  const locations = pick(dataPoint, ['location1', 'location2', 'location3', 'location4']);
  let value;
  if (locations['location4']) {
    value = locations['location4'];
  } else if (locations['location3']) {
    value = locations['location3'];
  } else if (locations['location2']) {
    value = locations['location2'];
  } else if (locations['location1']) {
    value = locations['location1'];
  }
  if (value) {
    const location = locationHierarchy.find((loc) => {
      return loc.key === value || loc.key === `${value}`;
    });
    if (location) {
      if (key === '_postalcodestart') {
        return location.upperPostalCode && location.upperPostalCode.length > 0
          ? location.upperPostalCode[0]
          : '';
      } else if (key === '_postalcodeend') {
        return location.lowerPostalCode && location.lowerPostalCode.length > 0
          ? location.lowerPostalCode[0]
          : '';
      }
    }
  }
  return '';
};

/**
 * Sets the variable initialization code for given key.
 * If the key has a (.), we only initialize the reference part.
 * Assumption is the part after the reference is a JS function.
 */
export const initVars = (value, key, vars, question?) => {
  if (isNullOrUndefined(value)) {
    value = '';
    if (question && question.default) {
      value = question.default;
      if (isNumeric(question)) {
        value = Number(value);
      }
    } else {
      if (question && isNumeric(question)) {
        value = 0;
      }
    }
  }
  const newValue = typeof value === 'string' ? `"${value.trim().replace(/\n/g, '\\n')}"` : `${value}`;
  if (key.indexOf('.') === -1) {
    vars.push(`var ${key} = ${newValue};`);
    return;
  }
  const ref = key.substr(0, key.indexOf('.'));
  vars.push(`var ${ref} = ${newValue};`);
};

export const getUserValues = (value, users) => {
  if (value) {
    const userNames: any = [];
    const userValues = value.split(',');
    users.forEach((u) => {
      if (userValues.indexOf(`${u.id}`) !== -1) {
        userNames.push(u.name);
      }
    });
    return userNames.join(',');
  }
  return '';
};

export const setQuestionValue = (
  qn,
  formUtils,
  id,
  currentValue,
  value,
  key,
  vars,
  dataPoint,
  forms,
  users,
  pois,
  locationHierarchy,
) => {
  switch (qn.type) {
    case 'SelectOneQuestion':
    case 'SelectMultipleQuestion':
    case 'LikertScaleQuestion':
      value = getSelectValues(formUtils, id, currentValue, value, qn, key, vars);
      break;
    case 'TableQuestion':
    case 'ReferenceTableQuestion':
    case 'Part_of_Question':
    case 'TaskQuestion':
      value = getChildValues(qn, id, key, dataPoint, forms, vars);
      break;
    case 'LookupValuesQuestion':
      value = getLookupValue(
        key,
        dataPoint[id],
        qn,
        formUtils.getLookupOptions(id),
        forms,
        vars,
        pois,
        locationHierarchy,
      );
      break;
    case 'SelectUserQuestion':
    case 'UserQuestion': {
      const userNames = getUserValues(dataPoint[id], users);
      initVars(userNames, key, vars);
      break;
    }
    default:
      initVars(value, key, vars, qn);
      break;
  }
  return value;
};

/**
 * Retrieves the row value for the given row.
 * @param dataPoint the object containing the row values.
 * @param parentQuestion - the parent question, Table question in this case.
 * @param vars - the array containing the variable declarations.
 */
export const getRowValue = (dataPoint, parentQuestion, vars) => {
  if (parentQuestion) {
    const rowId = dataPoint.Name;
    const row = parentQuestion.table.rows.listItem.find((row) => row.id === rowId);
    if (row) {
      initVars(row.rowValue, '_rowvalue', vars);
      return row.rowValue;
    }
  }
  return '';
};

export const updateScriptObject = (
  localSPSSToId: LooseObject,
  currentValues: LooseObject,
  formUtils: FormUtils,
  dataPoint: DataPoint,
  forms: LooseObject[],
  locationHierarchy: Locations,
  users: LooseObject,
  parentModel?: LooseObject,
  parentDataPoint?: LooseObject,
  pois?: LooseObject,
  question?: QuestionInterface,
  parentQuestion?: LooseObject,
): { updated: boolean; vars: any; updatedObj: LooseObject } => {
  let updated = false;
  const obj = { ...currentValues };
  const vars = [];
  for (const key in localSPSSToId) {
    const id = localSPSSToId[key];
    const qn = formUtils.getQuestion(id);
    const currentValue = obj[key];
    let value = dataPoint[id];

    if (key.indexOf('_location') === 0) {
      value = getLocationHierarchy(key, locationHierarchy, value, vars);
    } else if (key.indexOf('parent') !== -1) {
      if (parentModel && parentDataPoint) {
        value = getParentValues(key, parentModel, parentDataPoint, vars);
      }
    } else if (key === '_parntfld') {
      const subfrmfld = dataPoint['subfrmfld'];
      if (subfrmfld && parentModel) {
        const subFrmId = subfrmfld.split('_');
        const subFormQn = getQuestionFromId(subFrmId[1], parentModel);
        if (subFormQn) {
          value = `${subFormQn.text}`.replace(/\r|\n/g, ' ');
          initVars(value, key, vars);
        }
      }
    } else if (key === '_parntref' && parentQuestion) {
      value = parentQuestion.spssvariable;
      initVars(value, key, vars);
    } else if (key === '_username' || key === '_editor') {
      value = currentValue;
      initVars(currentValue, key, vars);
    } else if (key === '_rowvalue') {
      value = getRowValue(dataPoint, parentQuestion, vars);
    } else if (qn) {
      value = setQuestionValue(
        qn,
        formUtils,
        id,
        currentValue,
        value,
        key,
        vars,
        dataPoint,
        forms,
        users,
        pois,
        locationHierarchy,
      );
    } else if (question) {
      // this is not a question and not the set ref names, check for subforms.
      const ref = key.substring(1, key.indexOf('.'));
      const form = forms.find((f) => f.referenceName === ref);
      if (form) {
        value = getSubFormValues(key, form, question, dataPoint, formUtils.getModel(), vars);
      }
    }
    // }
    if (obj[key] !== value) {
      obj[key] = value;
      updated = true;
    }
  }
  return {
    updatedObj: obj,
    updated,
    vars,
  };
};

/*
export const parseQuestionTextScript = (
    localSPSSToId: LooseObject,
    currentValues: LooseObject,
    dataPoint: DataPoint,
    question: LooseObject,
    formUtils: FormUtils
  ) => {
    let updated = false;
    const obj = {...currentValues};
    const vars = [];
    for (const key in localSPSSToId) {
      const id = localSPSSToId[key];
      const qn = formUtils.getQuestion(id);
      const currentValue = obj[key];
      let value = dataPoint[id];

      if (qn) {
        if (qn.type === 'SelectOneQuestion' || qn.type === 'SelectMultipleQuestion'
          || qn.type === 'LikertScaleQuestion') {
          value = getSelectValues(formUtils, id, currentValue, value, qn, key, vars);
        } else if (qn.type === 'TableQuestion' || qn.type === 'Part_of_Question') {
          value = getChildValues(qn, id, key, dataPoint, forms, vars);
        } else if (qn.type === 'LookupValuesQuestion') {
          value = getLookupValue(
            key, dataPoint[id], qn, formUtils.getLookupOptions(id), forms, vars, pois, locationHierarchy
          );
        } else {
          initVars(value, key, vars);
        }
      }
    }
    return {
      updatedObj: obj, updated
    };
}; */

export const runScript = (script: string, vars: LooseObject) => {
  try {
    return eval('(function() {' + vars.join(' ') + script + '})();');
  } catch (e) {
    return null;
  }
};

export const filterSubforms = (
  script: string, subForm: FormInterface, subFormDataPoints: DataPoint[], mainForm: FormInterface, dataPoint: DataPoint
) => {
  const subFormUtils = getFormUtils(subForm);
  const formUtils = getFormUtils(mainForm);
  const allVariables: string[] = Array.from(new Set(script.match(/_[0-9a-zA-Z_$]*\.[0-9a-zA-Z_.$]*|_[0-9a-zA-Z_$]*/g)));
  const obj: LooseObject = {}; // mapping for _spss -> value
  const localSPSSToId: LooseObject = {}; // mapping for _spss -> id
  const validSubForms: DataPoint[] = [];
  let checkMainForm = false;

  const mainFormObj = {};
  const mainFormLocalSPSSToId = [];
  for (const v of allVariables) {
    if (v.startsWith(`_${subFormUtils.getModel().referenceName}`)) {
      const refName = v.substring(v.indexOf('.') + 1);
      const questionId = subFormUtils.getQuestionId(`_${refName}`);
      obj[`_${refName}`] = null;
      localSPSSToId[`_${refName}`] = questionId;
    } else {
      const id = formUtils.getQuestionId(v);
      mainFormObj[v] = dataPoint[id];
      mainFormLocalSPSSToId[v] = id;
      checkMainForm = true;
    }
  }
  let vars = [];
  if (checkMainForm) {
    const response = updateScriptObject(mainFormLocalSPSSToId, mainFormObj, formUtils, dataPoint, [], [], []);
    vars = vars.concat(response.vars);
  }
  for (const subFormDataPoint of subFormDataPoints) {
    const response =
    updateScriptObject(localSPSSToId, obj, subFormUtils, subFormDataPoint, [], [], []);
    const newScript = script.replace(`_${subFormUtils.getModel().referenceName}.`, '_');
    const valid = runScript(newScript, response.vars.concat(vars));
    if (valid) {
      validSubForms.push(subFormDataPoint);
    }
  }
  return validSubForms;
};
