import './Style.scss';
import * as React from 'react';
import { getLocalization } from 'global/global';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import moment from 'moment';
import { PaymentPlanInfo } from 'Interfaces/PaymentPlanInterface';
import TransactionBundle from '../form/TransactionBundle';
import { getTransactionCost } from '../utils';

interface Props {
  paymentPlanInfo: PaymentPlanInfo;
  openPaymentsTab: () => void;
  updateSubscription: (newValues: JSONInterface) => void;
}

function DetailsForm(props: Props) {
  const { paymentPlanInfo } = props;

  const getMinValidFrom = () => {
    const validFrom = moment(paymentPlanInfo.validTo).isAfter(moment()) ? moment(paymentPlanInfo.validTo) : moment();
    return validFrom.format('YYYY-MM-DD');
  };

  const getInitialData = () => {
    const validFrom = getMinValidFrom();
    const validTo = moment(validFrom).add(12, 'months').format('YYYY-MM-DD');
    return {
      validFrom: validFrom,
      validTo,
      package: props.paymentPlanInfo.plan || 'Pro'
    };
  };

  const [data, setData] = React.useState<JSONInterface>(getInitialData());
  const [minValidFrom] = React.useState(getMinValidFrom());
  const [validate, setValidate] = React.useState(false);

  const onDataChange = (name, value) => {
    if (name === 'validFrom') {
      const validTo = moment(value).add(12, 'months').format('YYYY-MM-DD');
      setData({...data, [name]: value, validTo});
    } else {
      setData({...data, [name]: value});
    }
  };

  React.useEffect(() => {
    props.updateSubscription(data);
  }, [data]);

  React.useEffect(() => {
    if (data['pricingMethod'] === 'Per User') {
      const users = Number(data['prepNumberOfUsers']) || 0;
      const charge = props.paymentPlanInfo[`chargePerUser${data['package']}`];
      const cost = users * Number(charge) * 10;
      setData({
        ...data,
        totalCost: (Math.round((cost + Number(data['oneTimePayment'] || 0)) * 100) / 100).toFixed(2)
      });
    } else if (data['pricingMethod'] === 'Per Transaction') {
      const users = Number(data['prepNumberOfUsers']) || 0;
      const charge = props.paymentPlanInfo[`chargePerUser${data['package']}`];
      const cost = users * Number(charge) * 10;
      const txCost = getTransactionCost(data['transactions']);
      setData({
        ...data,
        totalCost: (Math.round((cost + txCost + (Number(data['oneTimePayment'] || 0))) * 100) / 100).toFixed(2)
      });
    } else {
      setData({
        ...data,
        totalCost: (Math.round((Number(data['oneTimePayment'] || 0)) * 100) / 100).toFixed(2)
      });
    }
  }, [data.pricingMethod, data.transactions, data.prepNumberOfUsers, data.oneTimePayment]);

  const openPaymentsTab = () => {
    if (!data['totalCost'] || paymentPlanInfo.activeUsers > data['prepNumberOfUsers']) {
      setValidate(true);
    } else {
      props.openPaymentsTab();
    }
  };

  return (
    <Form className="payment-form">
      <Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>{getLocalization('preferredPackage')}</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={data['package'] || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onDataChange('package', e.target.value)}
          >
            <option value="">{getLocalization('selectOne')}</option>
            <option value="Light">{getLocalization('light')}</option>
            <option value="Pro">{getLocalization('pro')}</option>
            <option value="Custom">{getLocalization('custom')}</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{getLocalization('pricingMethod')}</Form.Label>
          <Form.Check
            id={`pricingMethod-per-user`}
            type={'radio'}
            label={getLocalization('userBased')}
            name={'pricingMethod'}
            checked={data['pricingMethod'] === 'Per User'}
            onChange={(e) => onDataChange('pricingMethod', e.target.checked ? 'Per User' : null )}
          />
          <Form.Check
            id={`pricingMethod-per-tx`}
            type={'radio'}
            label={getLocalization('transactionBased')}
            name={'pricingMethod'}
            checked={data['pricingMethod'] === 'Per Transaction'}
            onChange={(e) => onDataChange('pricingMethod', e.target.checked ? 'Per Transaction' : null )}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>{getLocalization('licenseActiveFrom')}</Form.Label>
          <Form.Control
            className="payments-date-input"
            type="date"
            size="sm"
            min={minValidFrom}
            value={data['validFrom']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDataChange('validFrom', e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{getLocalization('licenseValidTo')}</Form.Label>
          <Form.Control
            className="payments-date-input"
            type="date"
            size="sm"
            min={data.validFrom || moment().format('YYYY-MM-DD')}
            value={data['validTo']}
            readOnly
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDataChange('validTo', e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={6} controlId="no-of-users">
          <Form.Label>{
            getLocalization(data['pricingMethod'] === 'Per User' ? 'prepNumberOfUsers' : 'estimatedNoOfficeUsers')
          }</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={data['prepNumberOfUsers']}
            isInvalid={paymentPlanInfo.activeUsers > data['prepNumberOfUsers']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.value.match(new RegExp(/[^0-9]/))) {
                onDataChange('prepNumberOfUsers', e.target.value);
              } else {
                onDataChange('prepNumberOfUsers', data.prepNumberOfUsers);
              }
            }}
          />
          <Form.Control.Feedback type="invalid">{getLocalization('newLicenseAlert')}</Form.Control.Feedback>
        </Form.Group>
        {data['pricingMethod'] === 'Per Transaction' && (
          <TransactionBundle
            value={data['transactions']}
            onChange={(v) => onDataChange('transactions', v)}
          />
        )}
      </Row>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>{
            getLocalization('oneTimePayments')
          }</Form.Label>
          <Form.Text className="text-muted">
            {getLocalization('oneTimePaymentsAdvice')}
          </Form.Text>
          <Form.Control
            type="number"
            size="sm"
            value={data['oneTimePayment']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDataChange('oneTimePayment', e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>{getLocalization('totalCost')}</Form.Label>
          <InputGroup className="mb-3" size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>$</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              readOnly
              value={data['totalCost']}
              isInvalid={!data['totalCost'] && validate}
            />
          </InputGroup>
          {data['pricingMethod'] === 'Per User' && (
            <Form.Text>
              {`(${(Number(data['prepNumberOfUsers']) || 0) < 5 ? '12' : '10'} ${getLocalization('months')}
                * ${getLocalization('prepNumberOfUsers')}
                * ${getLocalization('perUserCost')}) + ${getLocalization('oneTimePayments')}`
              }
            </Form.Text>
          )}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Check
            id={'renew-automatically'}
            label={getLocalization('autoRenew')}
            checked={data['autoRenew']}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDataChange('autoRenew', e.target.checked)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Button size="sm" onClick={openPaymentsTab}>
            {getLocalization('proceedToPayment')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default DetailsForm;
