import * as React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'index';
import { StateInterface } from 'Interfaces/StateInterface';
import { MODULES } from 'Interfaces/ModuleSelectionInterface';
import { getTableColumnsVisibility, saveTableColumnsVisibility } from 'actions/tableActions';
import { LooseObject } from '../../Interfaces/LooseObject';
import { getLocalization } from '../../global/global';

interface Props {
  questionnaireId: string;
  columns: LooseObject[];
  updateColumns: (columns: LooseObject[]) => void;
}

export default function ColumnVisibilityComponent(props: Props) {

  const [show, setShow] = React.useState(false);
  const [columns, setColumns] = React.useState(props.columns);
  const dispatch = useAppDispatch();
  const hiddenColumns = useAppSelector((state: StateInterface) => state.tableHiddenColumns);
  const selectedModule = useAppSelector((state: StateInterface) => state.moduleSelection.selected);
  const [visibilityId, setVisibilityId] = React.useState<string | null>(null);

  React.useEffect(() => {
    setVisibilityId(selectedModule === MODULES.TASKS ? 'task-view' : props.questionnaireId);
  }, [props.questionnaireId, selectedModule]);

  const handleColumnCheck = (e) => {
    const cols = columns.map((column) => {
      if (column.dataField === e.target.value) {
        return {...column, hidden: !e.target.checked};
      }
      return column;
    });
    setColumns(cols);
  };

  React.useEffect(() => {
    if (visibilityId) {
      dispatch(getTableColumnsVisibility(visibilityId));
    }
  }, [visibilityId]);

  React.useEffect(() => {
    if (visibilityId && hiddenColumns[visibilityId]) {
      const cols = columns.map((column) => {
        const hidden = hiddenColumns[visibilityId].columns;
        return {...column, hidden: hidden.includes(column.dataField)};
      });
      setColumns(cols);
      props.updateColumns(cols);
    }
  }, [hiddenColumns]);

  const onToggle = (isOpen, event, metadata) => {
    console.log(metadata.source);
    if (metadata.source === 'select') {
      setShow(true);
    } else {
      setShow(isOpen);
      if (!isOpen) {
        props.updateColumns(columns);
        const hidden = columns.map(col => col.hidden ? col.dataField : null).filter(col => col);
        if (visibilityId) {
          void dispatch(saveTableColumnsVisibility(visibilityId, hidden));
        }
      }
    }
  };

  return (
    <Dropdown className="chart-content-spacing" onToggle={onToggle} show={show} alignRight={true}>
      <Dropdown.Toggle
        variant="primary"
        size="sm"
        id="chart-select-questions"
      >
        <i className="fa fa-list-ol" />
        {getLocalization('showHideColumns')}
      </Dropdown.Toggle>
      <Dropdown.Menu className="question-show-hide-component">
        {columns.map((col) => {
          if (col.dataField === 'row_id') {
            return null;
          }
          return (
            <Dropdown.Item as="div" key={`${col.dataField}-table-dropdown-qn`}>
              <Form.Check
                id={`table-question-down-checkbox-${col.dataField}`}
                type={'checkbox'}
                label={col.text && col.text !== '' ? col.text : col.dataField}
                onChange={handleColumnCheck}
                value={col.dataField}
                checked={!col.hidden}
              />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
