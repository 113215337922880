import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'typescript-fsa';
import { ThunkDispatch } from 'redux-thunk';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { AlertInterface } from 'Interfaces/AlertInterface';
import { SystemStatus } from 'Interfaces/SystemInterface';
import { getDataPointDocumentVersions } from 'actions/documentActions';
import { DocumentInterface } from 'Interfaces/DocumentInterface';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { StateInterface } from '../../../Interfaces/StateInterface';
import { ClientPersistInterface } from '../../../Interfaces/ClientPersistInterface';
import { Locations } from '../../../Interfaces/LocationInterface';
import SingleInstanceComponent from '../SingleInstanceComponent';
import { closeSingleInstance, fetchPOI, savePOI } from '../../../actions/pois';
import {
  setSingleInstance, unsetSingleInstanceState, updateOpenedDataPointAction
} from '../../../actions/moduleSelectionActions';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { SingleInstance } from '../../../Interfaces/ModuleSelectionInterface';
import { singleInstanceSelector } from '../../../reducers/moduleSelectionReducer';
import { QueryFiltersInterface } from '../../../reducers/filtersMenuReducer';
import { uniqueFormsSelector } from '../../../reducers/formsReducer';
import { showAlert } from '../../../actions';
import { saveSetting } from '../../../actions/userActions';
import { updateClientPersist } from '../../../actions/clientPersistActions';
import { toggleFilterMenu } from '../../../actions/filtersMenuActions';

interface StateProps {
  clientPersist: ClientPersistInterface;
  locationHierarchy: Locations;
  forms: FormInterface[];
  singleInstance?: SingleInstance;
  status: SystemStatus;
}

interface ActionProps {
  savePOI: (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) => Promise<Response>;
  fetchPOI: (
    formId: string, signal: AbortSignal, rowId?: number, filter?: QueryFiltersInterface[], query?: LooseObject
  ) => Promise<Response>;
  unsetSingleInstance: () => void;
  setSingleInstance: (singleInstance: SingleInstance) => void;
  // deletePOI: (rowId: number, id: string, formId: string, callBack: () => void) => void;
  closeSingleInstance: (id: string) => void;
  showAlert: (alert: AlertInterface) => void;
  saveSetting: (name: string, value: string) => void;
  updateClientPersist: (clientPersist: ClientPersistInterface) => void;
  toggleFilterMenu: (opened: boolean) => void;
  updateOpenedDataPointAction: () => void;
  getDataPointDocumentVersions: (id: string, format: string, signal: AbortSignal) => Promise<DocumentInterface[]>;
}

export type SingleInstanceProps = StateProps & ActionProps;

const SingleInstanceContainerClass = (props: SingleInstanceProps) => {
  if (props.singleInstance) {
    return (
      <SingleInstanceComponent
        {...props}
        singleInstance={props.singleInstance}
      />
    );
  }
  return null;
};

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    clientPersist: state.clientPersist,
    locationHierarchy: state.locations.collection,
    forms: uniqueFormsSelector(state),
    singleInstance: singleInstanceSelector(state),
    status: state.system.status
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    savePOI: (dataPoint: DataPoint, parameters?: JSONInterface, showProgress?: boolean) => {
      return dispatch(savePOI(dataPoint, parameters, showProgress));
    },
    fetchPOI: (
      formId: string, signal, rowId?, filter?, query?
    ) => {
      return dispatch(fetchPOI(formId, signal, rowId, filter, query));
    },
    unsetSingleInstance: () => dispatch(unsetSingleInstanceState()),
    setSingleInstance: (singleInstance) => dispatch(setSingleInstance(singleInstance)),
    closeSingleInstance: (id: string) => dispatch(closeSingleInstance(id)),
    showAlert: (alert: AlertInterface) => dispatch(showAlert(alert)),
    saveSetting: (name: string, value: string) => dispatch(saveSetting(name, value)),
    updateClientPersist: (clientPersist: ClientPersistInterface) => dispatch(updateClientPersist(clientPersist)),
    toggleFilterMenu: (open: boolean) => {
      dispatch(toggleFilterMenu(open));
    },
    updateOpenedDataPointAction: () => dispatch(updateOpenedDataPointAction(null)),
    getDataPointDocumentVersions: (id: string, format, signal: AbortSignal) => {
      return dispatch(getDataPointDocumentVersions(id, format, signal));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleInstanceContainerClass);
